import './App.css';
import Header from './components/Header';
import Banner from './components/Banner';

function App() {
  return (
    <>
      <Header />
      <main>
        <Banner />
      </main>
    </>
  );
}

export default App;